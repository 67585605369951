import LineGradient from "../components/LineGradient";
import useMediaQuery from "../hooks/useMediaQuery";
import { motion } from "framer-motion";

const MySkills = () => {
    const isAboveMediumScreens = useMediaQuery("(min-width: 1060px)");

    return (
        <section id="skills" className="pt-10 pb-24">            
            {/* Header and Image Section */}
            <div className="md:flex md:justify-between h-fit md:gap-16 mt-32">
                <motion.div className="md:w-2/5"
                    initial="hidden" whileInView="visible" viewport={{once: true, amount: 0.5}} transition={{delay: 0.1, duration: 0.5}} 
                    variants={{ hidden: {opacity: 0, x: -50 }, visible: {opacity: 1, x: 0 }}}>
                        <p className="font-poppin font-semibold text-4xl mb-5">
                            My <span className="text-green">Skills</span>
                        </p>
                        <LineGradient width="w-1/3" />
                        <p className="mt-10 mb-5">
                        Professional Full-Stack Web Developer with an emphasis on front-end.
                        </p>
                        <div className="flex">
                            <motion.div className="md:w-1/3 w-1/6 m-1"
                            initial="hidden" whileInView="visible" viewport={{once: true, amount: 0.5}} transition={{delay: 0.2, duration: 0.5}} 
                            variants={{ hidden: {opacity: 0, y: 50 }, visible: {opacity: 1, y: 0 }}}>
                            <div className="relative h-28">                            
                                <img className="ml-auto mr-auto" alt="TS" src="../assets/ts.png" /><p className="ml-auto mr-auto w-2/3 font-poppins text-sm text-center">TypeScript</p>
                            </div>
                            </motion.div>
                            <motion.div className="md:w-1/3 w-1/6 m-1"
                            initial="hidden" whileInView="visible" viewport={{once: true, amount: 0.5}} transition={{delay: 0.3, duration: 0.5}} 
                            variants={{ hidden: {opacity: 0, y: 50 }, visible: {opacity: 1, y: 0 }}}>
                            <div className="relative h-28">                            
                                <img className="ml-auto mr-auto" alt="C-Sharp" src="../assets/c-sharp.png" /><p className="ml-auto mr-auto w-2/3 font-poppins text-sm text-center">C Sharp</p>
                            </div>
                            </motion.div>
                            <motion.div className="md:w-1/3 w-1/6 m-1"
                            initial="hidden" whileInView="visible" viewport={{once: true, amount: 0.5}} transition={{delay: 0.4, duration: 0.5}} 
                            variants={{ hidden: {opacity: 0, y: 50 }, visible: {opacity: 1, y: 0 }}}>
                            <div className="relative h-28">                            
                                <img className="ml-auto mr-auto" alt="SQL" src="../assets/sql.png" /><p className="ml-auto mr-auto w-2/3 font-poppins text-sm text-center">SQL</p>
                            </div>
                            </motion.div>
                            <motion.div className="md:w-1/3 w-1/6 m-1"
                            initial="hidden" whileInView="visible" viewport={{once: true, amount: 0.5}} transition={{delay: 0.5, duration: 0.5}} 
                            variants={{ hidden: {opacity: 0, y: 50 }, visible: {opacity: 1, y: 0 }}}>
                            <div className="relative h-28">                            
                                <img className="ml-auto mr-auto" alt="SQLite" src="../assets/sqlite.png" /><p className="ml-auto mr-auto w-2/3 font-poppins text-sm text-center">SQLite</p>
                            </div>
                            </motion.div>
                            <motion.div className="md:w-1/3 w-1/6 m-1"
                            initial="hidden" whileInView="visible" viewport={{once: true, amount: 0.5}} transition={{delay: 0.6, duration: 0.5}} 
                            variants={{ hidden: {opacity: 0, y: 50 }, visible: {opacity: 1, y: 0 }}}>
                            <div className="relative h-28">                            
                                <img className="ml-auto mr-auto" alt="Git" src="../assets/git.png" /><p className="ml-auto mr-auto w-2/3 font-poppins text-sm text-center">Git</p>
                            </div>
                            </motion.div>
                            <motion.div className="md:w-1/3 w-1/6 m-1"
                            initial="hidden" whileInView="visible" viewport={{once: true, amount: 0.5}} transition={{delay: 0.7, duration: 0.5}} 
                            variants={{ hidden: {opacity: 0, y: 50 }, visible: {opacity: 1, y: 0 }}}>
                            <div className="relative h-28">                            
                                <img className="ml-auto mr-auto" alt="Github" src="../assets/github.png" /><p className="ml-auto mr-auto w-2/3 font-poppins text-sm text-center">Github</p>
                            </div>
                            </motion.div>
                        </div>
                        <div className="flex">
                            <motion.div className="md:w-1/3 w-1/6 m-1"
                            initial="hidden" whileInView="visible" viewport={{once: true, amount: 0.5}} transition={{delay: 0.8, duration: 0.5}} 
                            variants={{ hidden: {opacity: 0, y: 50 }, visible: {opacity: 1, y: 0 }}}>
                            <div className="relative h-28">                            
                                <img className="ml-auto mr-auto" alt="HTML5" src="../assets/html5.png" /><p className="ml-auto mr-auto w-2/3 font-poppins text-sm text-center">HTML5</p>
                            </div>
                            </motion.div>
                            <motion.div className="md:w-1/3 w-1/6 m-1"
                            initial="hidden" whileInView="visible" viewport={{once: true, amount: 0.5}} transition={{delay: 0.9, duration: 0.5}} 
                            variants={{ hidden: {opacity: 0, y: 50 }, visible: {opacity: 1, y: 0 }}}>
                            <div className="relative h-28">                            
                                <img className="ml-auto mr-auto" alt="CSS" src="../assets/css.png" /><p className="ml-auto mr-auto w-2/3 font-poppins text-sm text-center">CSS</p>
                            </div>
                            </motion.div>                     
                            <motion.div className="md:w-1/3 w-1/6 m-1"
                            initial="hidden" whileInView="visible" viewport={{once: true, amount: 0.5}} transition={{delay: 1.1, duration: 0.5}} 
                            variants={{ hidden: {opacity: 0, y: 50 }, visible: {opacity: 1, y: 0 }}}>
                            <div className="relative h-28">                            
                                <img className="ml-auto mr-auto" alt="Visual Studio Community" src="../assets/vscomm.png" /><p className="ml-auto mr-auto w-2/3 font-poppins text-sm text-center">VS Community</p>
                            </div> 
                            </motion.div>
                            <motion.div className="md:w-1/3 w-1/6 m-1"
                            initial="hidden" whileInView="visible" viewport={{once: true, amount: 0.5}} transition={{delay: 1.2, duration: 0.5}} 
                            variants={{ hidden: {opacity: 0, y: 50 }, visible: {opacity: 1, y: 0 }}}>
                            <div className="relative h-28">                            
                                <img className="ml-auto mr-auto" alt="Visual Studio Code" src="../assets/vscode.png" /><p className="ml-auto mr-auto w-2/3 font-poppins text-sm text-center">VS Code</p>
                            </div>
                            </motion.div>
                            <motion.div className="md:w-1/3 w-1/6"
                            initial="hidden" whileInView="visible" viewport={{once: true, amount: 0.5}} transition={{delay: 1.3, duration: 0.5}} 
                            variants={{ hidden: {opacity: 0, y: 50 }, visible: {opacity: 1, y: 0 }}}>
                            <div className="relative h-28">                            
                                <img className="ml-auto mr-auto" alt="React" src="../assets/react.png" /><p className="ml-auto mr-auto w-2/3 font-poppins text-sm text-center">React</p>
                            </div>
                            </motion.div>
                            <motion.div className="md:w-1/3 w-1/6"
                            initial="hidden" whileInView="visible" viewport={{once: true, amount: 0.5}} transition={{delay: 1.3, duration: 0.5}} 
                            variants={{ hidden: {opacity: 0, y: 50 }, visible: {opacity: 1, y: 0 }}}>
                            <div className="relative h-28">                            
                                <img className="ml-auto mr-auto" alt="Redux" src="../assets/redux.png" /><p className="ml-auto mr-auto w-2/3 font-poppins text-sm text-center">Redux</p>
                            </div>
                            </motion.div>
                        </div>
                        <div className="flex">
                            <motion.div className="md:w-1/3 w-1/6 m-1"
                            initial="hidden" whileInView="visible" viewport={{once: true, amount: 0.5}} transition={{delay: 1.4, duration: 0.5}} 
                            variants={{ hidden: {opacity: 0, y: 50 }, visible: {opacity: 1, y: 0 }}}>
                            <div className="relative h-28">                            
                                <img className="ml-auto mr-auto" alt="Maya" src="../assets/maya.png" /><p className="ml-auto mr-auto w-2/3 font-poppins text-sm text-center">Autodesk Maya</p>
                            </div>
                            </motion.div>
                            <motion.div className="md:w-1/3 w-1/6 m-1"
                            initial="hidden" whileInView="visible" viewport={{once: true, amount: 0.5}} transition={{delay: 1.5, duration: 0.5}} 
                            variants={{ hidden: {opacity: 0, y: 50 }, visible: {opacity: 1, y: 0 }}}>
                            <div className="relative h-28">                            
                                <img className="ml-auto mr-auto" alt="Figma" src="../assets/figma.png" /><p className="ml-auto mr-auto w-2/3 font-poppins text-sm text-center">Figma</p>
                            </div>
                            </motion.div>
                            <motion.div className="md:w-1/3 w-1/6 m-1"
                            initial="hidden" whileInView="visible" viewport={{once: true, amount: 0.5}} transition={{delay: 1.6, duration: 0.5}} 
                            variants={{ hidden: {opacity: 0, y: 50 }, visible: {opacity: 1, y: 0 }}}>
                            <div className="relative h-28">                            
                                <img className="ml-auto mr-auto" alt="Adobe Photoshop" src="../assets/ps.png" /><p className="ml-auto mr-auto w-2/3 font-poppins text-sm text-center">Adobe Photoshop</p>
                            </div>
                            </motion.div>
                            <motion.div className="md:w-1/3 w-1/6 m-1" 
                            initial="hidden" whileInView="visible" viewport={{once: true, amount: 0.5}} transition={{delay: 1.7, duration: 0.5}} 
                            variants={{ hidden: {opacity: 0, y: 50 }, visible: {opacity: 1, y: 0 }}}>
                            <div className="relative h-28">                            
                                <img className="ml-auto mr-auto" alt="Adobe Illustrator" src="../assets/ai.png" /><p className="ml-auto mr-auto w-2/3 font-poppins text-sm text-center">Adobe Illustrator</p>
                            </div>
                            </motion.div>
                            <motion.div className="md:w-1/3 w-1/6 m-1"
                            initial="hidden" whileInView="visible" viewport={{once: true, amount: 0.5}} transition={{delay: 1.8, duration: 0.5}} 
                            variants={{ hidden: {opacity: 0, y: 50 }, visible: {opacity: 1, y: 0 }}}>
                            <div className="relative h-28">                            
                                <img className="ml-auto mr-auto" alt="Adobe After Effects" src="../assets/ae.png" /><p className="ml-auto mr-auto w-2/3 font-poppins text-sm text-center">After Effects</p>
                            </div>
                            </motion.div>
                            <motion.div className="md:w-1/3 w-1/6 m-1"
                            initial="hidden" whileInView="visible" viewport={{once: true, amount: 0.5}} transition={{delay: 1.9, duration: 0.5}} 
                            variants={{ hidden: {opacity: 0, y: 50 }, visible: {opacity: 1, y: 0 }}}>
                            <div className="relative h-28">                            
                                <img className="ml-auto mr-auto" alt="Substance Painter" src="../assets/sp.png" /><p className="ml-auto mr-auto w-2/3 font-poppins text-sm text-center">Substance Painter</p>
                            </div>
                            </motion.div>
                        </div>
                </motion.div>

                <div className="mt-16 md:mt-0">
                    {isAboveMediumScreens ? (
                        <div className="relative z-0 ml-20 before:absolute before:-top-10 before:-left-10 before:w-full before:h-full before:border-2 before:border-blue before:z-[-1]">
                            <img alt="skills" src="assets/skills-image.png" className="z-10" />
                        </div>
                    ) : (
                        <img alt="skills" src="assets/skills-image.png" className="z-10" />
                    )}
                </div>
            </div>
        </section>
    )
}

export default MySkills;