

const SocialMediaIcons = () => {
    return (
        <div className="flex justify-center md:justify-start my-10 gap-7">
            <a className="hover:opacity-50 transition duration-500" href="https://www.linkedin.com/in/haroutunpanosyan/" target="_blank" rel="noreferrer">
                <img alt="linkedin-link" src="../assets/linkedin.png" />
            </a>

            <a className="hover:opacity-50 transition duration-500" href="https://github.com/HaroutunPanosyan" target="_blank" rel="noreferrer">
                <img alt="github-link" src="../assets/github.png" />
            </a>

            <a className="hover:opacity-50 transition duration-500" href="https://www.linkedin.com/in/haroutunpanosyan/" target="_blank" rel="noreferrer">
                <img alt="resume-link" src="../assets/resume.png" />
            </a>
            
            {/* <a className="hover:opacity-50 transition duration-500" href="https://www.codewars.com/users/HaroutunPanosyan" target="_blank" rel="noreferrer">
                <img alt="codewars-link" src="../assets/codewars.png" />
            </a> */}
        </div>        
    )
}

export default SocialMediaIcons;