import LineGradient from "../components/LineGradient";
import { useForm } from 'react-hook-form';
import { motion } from "framer-motion";



const Contact = () => {
    const { register, trigger, formState: {errors} }= useForm();

    const onSubmit = async (e) => {
        const isValid = await trigger();
        if (!isValid) {
            e.preventDefault();            
        }
    }

    return (
        <section id="contact" className="py-48">
            {/* Headings */}
            <motion.div className="flex justify-end w-full"
            initial="hidden" whileInView="visible" viewport={{once: true, amount: 0.5}} transition={{duration: 0.5}} 
                variants={{ hidden: {opacity: 0, x: -50 }, visible: {opacity: 1, x: 0 },}}>
                    <div>                        
                        <p className="font-display font-semibold text-4xl">Contact Me</p>
                        <div className="flex md:justify-end my-5">
                            <LineGradient width="w-full" />
                        </div>
                    </div>
            </motion.div>

            {/* Form & Image */}
            <div className="md:flex md:justify-between gap-16 mt-5">
                <motion.div initial="hidden" whileInView="visible" viewport={{once: true, amount: 0.5}} transition={{duration: 0.5}} 
                variants={{ hidden: {opacity: 0, y: 50 }, visible: {opacity: 1, y: 0 },}}>
                    <img src="../assets/contact-image.jpeg" alt="contact" className="rounded"/>
                </motion.div>

                <motion.div className="basis-1/2 mt-10 md:mt-0"
                initial="hidden" whileInView="visible" viewport={{once: true, amount: 0.5}} transition={{ delay: 0.2, duration: 0.5}} 
                variants={{ hidden: {opacity: 0, y: 50 }, visible: {opacity: 1, y: 0 },}}>
                    <form target="_blank" onSubmit={onSubmit} action="https://formsubmit.co/98ede0d922909029aa93c46321912835" method="POST">
                        <input className="rounded mb-5 w-full bg-blue font-semibold placeholder-opaque-black" type="text" placeholder="Name" {...register("name", { requred: true, maxLength: 100, })} />
                        {errors.name && (
                            <p className="text-red mt-1">
                                {errors.name.type === 'required' && "This Field is required."}
                                {errors.name.type === 'maxLength' && "Max length is 100 characters."}
                            </p>
                        )}

                        <input className="rounded mb-5 w-full bg-blue font-semibold placeholder-opaque-black" type="text" placeholder="Email" 
                        {...register("email", { requred: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9._%+-]+\.[A-Z]{2,}$/i, })} />
                        {errors.email && (
                            <p className="text-red mt-1">
                                {errors.email.type === 'required' && "This Field is required."}
                                {errors.email.type === 'pattern' && "Invalid Email address."}
                            </p>
                        )}

                        <textarea className="rounded mb-5 w-full bg-blue font-semibold placeholder-opaque-black" type="text" placeholder="Message" rows="4" cols="50"
                        {...register("message", { requred: true, maxLength: 2000 })} />
                        {errors.message && (
                            <p className="text-red mt-1">
                                {errors.message.type === 'required' && "This Field is required."}
                                {errors.message.type === 'maxLength' && "Max length of message is 2000 characters."}
                            </p>
                        )}

                        <button className="rounded p-5 bg-yellow font-semibold text-deep-blue mt-5 hover:bg-red hover:text-white transition duration-500" type="submit">Send me a message</button>
                    </form>
                </motion.div>
            </div>

        </section>
    )
}


export default Contact;