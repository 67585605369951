import SocialMediaIcons from "../components/SocialMediaIcons";


const Footer = () => {
    return (
        <footer className="h-50 bg-gradient-raingreen-2 pt-1 pb-5">
            <div className="w-5/6 mx-auto">
                <SocialMediaIcons />
                <div className="md:flex justify-center md:justify-between text-center">
                    <p className="font-display font-semibold text-2xl text-yellow">Haroutun Panosyan</p>
                    <p className="font-display text-md text-yellow">Copyright © Haroutun Panosyan. All Rights Reserved.</p>
                </div>
            </div>
        </footer>
    )
}

export default Footer;