import LineGradient from "../components/LineGradient";
import { motion } from "framer-motion";


const Projects = () => {
    return (
        <section id="projects" className="pb-48 pt-48">
            {/* Headings */}
            <motion.div className="md:w-2/5 mx-auto text-center" initial="hidden" whileInView="visible" viewport={{ once: true, amount: 0.5 }} transition={{ duration: 0.5 }}
                variants={{ hidden: { opacity: 0, y: -50 }, visible: { opacity: 1, y: 0 },}}>
                        <div>
                            <p className="font-poppins font-semibold text-4xl">
                                <span className="text-green">Pro</span>jects
                            </p>
                            <div className="flex justify-center mt-5">
                                <LineGradient width="w-2/3" />
                            </div>
                        </div>
                            <p className="mt-10 mb-10">
                                Projects are more than mere tasks; they are profound learning experiences that sculpt our abilities and character. Each project, 
                                presents opportunities for growth, and problem-solving. Through challenges and triumphs, 
                                we acquire new skills, deepen our expertise, and become more resilient.
                            </p>
                </motion.div>
            <div>
                <LineGradient />
            </div>

            <motion.div className=" mx-auto text-center" initial="hidden" whileInView="visible" viewport={{ once: true, amount: 0.5 }} transition={{ duration: 0.5 }}
                variants={{ hidden: { opacity: 0, y: -50 }, visible: { opacity: 1, y: 0 },}}>        
                <div className="flex justify-center mt-20 ">
                    <div className="sm:grid sm:grid-cols-2 text-center">
                        <img className="ml-auto mr-auto lg:mx-0 lg:mt-16 xl:mt-0" src="../assets/gtb.png" alt="Golden Time Banking" />
                        <div className="ml-auto mr-auto pl-5 pr-5">
                            <p className="text-4xl mt-5 md:mt-0">
                                Golden Time Banking App
                            </p>
                            <div className="mt-5">
                                <LineGradient />
                            </div>
                            <p className="mt-5 max-w-[600px]">
                            Sleek dark themed interface welcomes users with an easy-on-the-eyes color pallet.
                            Clear and obvious navigation with an emphasis on responsiveness.  
                            Fully equipped with Create, Read, and Update functionality.
                            Login and Signup API tailored and written with a Google Login workflow for ease of use.
                            </p>
                            <p className="mt-5 max-w-[600px]">Skills used for this project:</p>      
                            <p className="mt-5 max-w-[600px]">Front-end:</p>      
                            <div className="flex">
                                <motion.div className="md:w-1/3 w-1/6 m-1"
                                initial="hidden" whileInView="visible" viewport={{once: true, amount: 0.5}} transition={{delay: 0.1, duration: 0.5}} 
                                variants={{ hidden: {opacity: 0, y: 50 }, visible: {opacity: 1, y: 0 }}}>
                                    <div className="relative h-28">                            
                                        <img className="ml-auto mr-auto" alt="React" src="../assets/react.png" /><p className="ml-auto mr-auto w-2/3 font-poppins text-sm text-center">React</p>
                                    </div>
                                </motion.div>
                                <motion.div className="md:w-1/3 w-1/6 m-1"
                                initial="hidden" whileInView="visible" viewport={{once: true, amount: 0.5}} transition={{delay: 0.15, duration: 0.5}} 
                                variants={{ hidden: {opacity: 0, y: 50 }, visible: {opacity: 1, y: 0 }}}>
                                    <div className="relative h-28">                            
                                        <img className="ml-auto mr-auto" alt="Redux" src="../assets/redux.png" /><p className="ml-auto mr-auto w-2/3 font-poppins text-sm text-center">Redux</p>
                                    </div>
                                </motion.div>
                                <motion.div className="md:w-1/3 w-1/6 m-1"
                                initial="hidden" whileInView="visible" viewport={{once: true, amount: 0.5}} transition={{delay: 0.2, duration: 0.5}} 
                                variants={{ hidden: {opacity: 0, y: 50 }, visible: {opacity: 1, y: 0 }}}>
                                    <div className="relative h-28">                            
                                        <img className="ml-auto mr-auto" alt="Typescript" src="../assets/ts.png" /><p className="ml-auto mr-auto w-2/3 font-poppins text-sm text-center">TypeScript</p>
                                    </div>
                                </motion.div>
                                <motion.div className="md:w-1/3 w-1/6 m-1"
                                initial="hidden" whileInView="visible" viewport={{once: true, amount: 0.5}} transition={{delay: 0.3, duration: 0.5}} 
                                variants={{ hidden: {opacity: 0, y: 50 }, visible: {opacity: 1, y: 0 }}}>
                                    <div className="relative h-28">                            
                                        <img className="ml-auto mr-auto" alt="Material UI" src="../assets/mui.png" /><p className="ml-auto mr-auto w-2/3 font-poppins text-sm text-center">Material UI</p>
                                    </div>
                                </motion.div>
                                <motion.div className="md:w-1/3 w-1/6 m-1"
                                initial="hidden" whileInView="visible" viewport={{once: true, amount: 0.5}} transition={{delay: 0.4, duration: 0.5}} 
                                variants={{ hidden: {opacity: 0, y: 50 }, visible: {opacity: 1, y: 0 }}}>
                                    <div className="relative h-28">                            
                                        <img className="ml-auto mr-auto" alt="VS Code" src="../assets/vscode.png" /><p className="ml-auto mr-auto w-2/3 font-poppins text-sm text-center">VS Code</p>
                                    </div>
                                </motion.div>
                            </div>
                            <p className="mt-5 max-w-[600px]">Back-end:</p>      
                            <div className="flex">
                                <motion.div className="md:w-1/3 w-1/6 m-1"
                                initial="hidden" whileInView="visible" viewport={{once: true, amount: 0.5}} transition={{delay: 0.1, duration: 0.5}} 
                                variants={{ hidden: {opacity: 0, y: 50 }, visible: {opacity: 1, y: 0 }}}>
                                    <div className="relative h-28">                            
                                        <img className="ml-auto mr-auto" alt="C-Sharp" src="../assets/c-sharp.png" /><p className="ml-auto mr-auto w-2/3 font-poppins text-sm text-center">C-Sharp</p>
                                    </div>
                                </motion.div>
                                <motion.div className="md:w-1/3 w-1/6 m-1"
                                initial="hidden" whileInView="visible" viewport={{once: true, amount: 0.5}} transition={{delay: 0.2, duration: 0.5}} 
                                variants={{ hidden: {opacity: 0, y: 50 }, visible: {opacity: 1, y: 0 }}}>
                                    <div className="relative h-28">                            
                                        <img className="ml-auto mr-auto" alt="SQL" src="../assets/sql.png" /><p className="ml-auto mr-auto w-2/3 font-poppins text-sm text-center">mySQL</p>
                                    </div>
                                </motion.div>
                                <motion.div className="md:w-1/3 w-1/6 m-1"
                                initial="hidden" whileInView="visible" viewport={{once: true, amount: 0.5}} transition={{delay: 0.3, duration: 0.5}} 
                                variants={{ hidden: {opacity: 0, y: 50 }, visible: {opacity: 1, y: 0 }}}>
                                    <div className="relative h-28">                            
                                        <img className="ml-auto mr-auto" alt="EF8" src="../assets/ef8.png" /><p className="ml-auto mr-auto w-2/3 font-poppins text-sm text-center">Entity Framework 8</p>
                                    </div>
                                </motion.div>
                                <motion.div className="md:w-1/3 w-1/6 m-1"
                                initial="hidden" whileInView="visible" viewport={{once: true, amount: 0.5}} transition={{delay: 0.4, duration: 0.5}} 
                                variants={{ hidden: {opacity: 0, y: 50 }, visible: {opacity: 1, y: 0 }}}>
                                    <div className="relative h-28">                            
                                        <img className="ml-auto mr-auto" alt="VS" src="../assets/vscomm.png" /><p className="ml-auto mr-auto w-2/3 font-poppins text-sm text-center">VS Community</p>
                                    </div>
                                </motion.div>
                            </div>
                        </div>
                    </div>
                </div>
            </motion.div>

            <motion.div className="flex md:mt-20 justify-center md:mb-20"
                    initial="hidden" whileInView="visible" viewport={{once: true, amount: 0.5}} transition={{delay: 0.2, duration: 0.5}} 
                    variants={{ hidden: {opacity: 0, x: -50 }, visible: {opacity: 1, x: 0 }}}>
                <a className="bg-gradient-rainblue text-deep-blue rounded-sm py-3 px-7 font-semibold hover:bg-blue hover:text-white transition duration-500" 
                href="https://gtb.haroutunpanosyan.com">
                    See the Webpage
                </a>                                      
            </motion.div>
    
            <div>
                <LineGradient />
            </div>
            <motion.div className=" mx-auto text-center" initial="hidden" whileInView="visible" viewport={{ once: true, amount: 0.5 }} transition={{ duration: 0.5 }}
                variants={{ hidden: { opacity: 0, y: -50 }, visible: { opacity: 1, y: 0 },}}>        
                <div className="flex justify-center mt-20 ">
                    <div className="sm:grid sm:grid-cols-2 text-center">
                        <img className="ml-auto mr-auto lg:mx-0 lg:mt-16 xl:mt-0" src="../assets/card-creator.png" alt="Card Creator App" />
                        <div className="ml-auto mr-auto pl-5 pr-5">
                            <p className="text-4xl mt-5 md:mt-0">
                                Magic the Gathering Card Generator
                            </p>
                            <div className="mt-5">
                                <LineGradient />
                            </div>
                            <p className="mt-5 max-w-[600px]">A Magic the Gathering card generator that allows users to effortlessly create customized cards with their own specifications.
                            It has full CRUD functionality with the addition of a login feature that separates user credentials to ensure no two users see cards they did not create.</p>
                            <p className="mt-5 max-w-[600px]">Skills used for this project:</p>      

                            <div className="flex">
                            <motion.div className="md:w-1/3 w-1/6 m-1"
                            initial="hidden" whileInView="visible" viewport={{once: true, amount: 0.5}} transition={{delay: 0.2, duration: 0.5}} 
                            variants={{ hidden: {opacity: 0, y: 50 }, visible: {opacity: 1, y: 0 }}}>
                            <div className="relative h-28">                            
                                <img className="ml-auto mr-auto" alt="JS" src="../assets/js.png" /><p className="ml-auto mr-auto w-2/3 font-poppins text-sm text-center">Java Script</p>
                            </div>
                            </motion.div>
                            <motion.div className="md:w-1/3 w-1/6 m-1"
                            initial="hidden" whileInView="visible" viewport={{once: true, amount: 0.5}} transition={{delay: 0.3, duration: 0.5}} 
                            variants={{ hidden: {opacity: 0, y: 50 }, visible: {opacity: 1, y: 0 }}}>
                            <div className="relative h-28">                            
                                <img className="ml-auto mr-auto" alt="C-Sharp" src="../assets/c-sharp.png" /><p className="ml-auto mr-auto w-2/3 font-poppins text-sm text-center">C Sharp</p>
                            </div>
                            </motion.div>
                            <motion.div className="md:w-1/3 w-1/6 m-1"
                            initial="hidden" whileInView="visible" viewport={{once: true, amount: 0.5}} transition={{delay: 0.4, duration: 0.5}} 
                            variants={{ hidden: {opacity: 0, y: 50 }, visible: {opacity: 1, y: 0 }}}>
                            <div className="relative h-28">                            
                                <img className="ml-auto mr-auto" alt="HTML" src="../assets/html5.png" /><p className="ml-auto mr-auto w-2/3 font-poppins text-sm text-center">HTML</p>
                            </div>
                            </motion.div>                            
                            <motion.div className="md:w-1/3 w-1/6 m-1"
                            initial="hidden" whileInView="visible" viewport={{once: true, amount: 0.5}} transition={{delay: 0.6, duration: 0.5}} 
                            variants={{ hidden: {opacity: 0, y: 50 }, visible: {opacity: 1, y: 0 }}}>
                            <div className="relative h-28">                            
                                <img className="ml-auto mr-auto" alt="CSS" src="../assets/css.png" /><p className="ml-auto mr-auto w-2/3 font-poppins text-sm text-center">CSS</p>
                            </div>
                            </motion.div>
                            <motion.div className="md:w-1/3 w-1/6 m-1"
                            initial="hidden" whileInView="visible" viewport={{once: true, amount: 0.5}} transition={{delay: 0.7, duration: 0.5}} 
                            variants={{ hidden: {opacity: 0, y: 50 }, visible: {opacity: 1, y: 0 }}}>
                            <div className="relative h-28">                            
                                <img className="ml-auto mr-auto" alt="Github" src="../assets/github.png" /><p className="ml-auto mr-auto w-2/3 font-poppins text-sm text-center">Github</p>
                            </div>
                            </motion.div>
                            <motion.div className="md:w-1/3 w-1/6 m-1"
                            initial="hidden" whileInView="visible" viewport={{once: true, amount: 0.5}} transition={{delay: 0.5, duration: 0.5}} 
                            variants={{ hidden: {opacity: 0, y: 50 }, visible: {opacity: 1, y: 0 }}}>
                            <div className="relative h-28">                            
                                <img className="ml-auto mr-auto" alt="SQLite" src="../assets/sqlite.png" /><p className="ml-auto mr-auto w-2/3 font-poppins text-sm text-center">SQLite</p>
                            </div>
                            </motion.div>
                            <motion.div className="md:w-1/3 w-1/6 m-1"
                            initial="hidden" whileInView="visible" viewport={{once: true, amount: 0.5}} transition={{delay: 0.7, duration: 0.5}} 
                            variants={{ hidden: {opacity: 0, y: 50 }, visible: {opacity: 1, y: 0 }}}>
                            <div className="relative h-28">                            
                                <img className="ml-auto mr-auto" alt="dot net core" src="../assets/netcore.png" /><p className="ml-auto mr-auto w-2/3 font-poppins text-sm text-center">.NET Core</p>
                            </div>
                            </motion.div>
                        </div> 
                    </div>
                    </div>              
                </div>
            </motion.div>

            <motion.div className="flex md:mt-20 justify-center"
                    initial="hidden" whileInView="visible" viewport={{once: true, amount: 0.5}} transition={{delay: 0.2, duration: 0.5}} 
                    variants={{ hidden: {opacity: 0, x: -50 }, visible: {opacity: 1, x: 0 }}}>
                <a className="bg-gradient-rainblue text-deep-blue rounded-sm py-3 px-7 font-semibold hover:bg-blue hover:text-white transition duration-500" 
                href="https://github.com/TrillOnGit/CustomCardApp">
                    See The Code on Github
                </a>
                <a className="rounded-r-sm bg-gradient-rainblue py-0.5 pr-0.5" href="https://youtu.be/v4FxhCyeyj4">
                <div className="bg-deep-blue hover:text-yellow transition duration-500 w-full h-full flex items-center justify-center font-display px-10">
                    YouTube Walkthrough
                </div>                            
                </a>                        
            </motion.div>

                    <div className="mt-20">
                        <LineGradient />
                    </div>                
                <div className="xl:flex justify-center md:mt-10 lg:mt-12 xl:mt-32 ml-auto mr-auto">
                    <div className="md:flex md:mt-8 xl:mt-0">
                        <motion.div initial="hidden" whileInView="visible" viewport={{ once: true, amount: 0.2 }} transition={{ duration: 0.5 }}
                        variants={{ hidden: { opacity: 0, x: -50 }, visible: { opacity: 1, x: 0 },}}>
                            <div className="md:px-4 xl:px-0 md:mt-8 justify-center xl:mr-32 xl:mt-80 ">
                                <img className="ml-auto mr-auto" src="../assets/ideate.png" alt="ideate"/> 
                                <p className="ml-auto mr-auto pt-2 text-center max-w-[350px] text-xl">Ideate</p>
                                <p className="ml-auto mr-auto pt-2 pb-4 text-center max-w-[450px]">
                                    Without ideation there is no beginning, no ignition, no spark. It is a designers conceptualization phase where the projects 
                                    creative direction and goal are fully explored. It's the 'what' phase of the project.
                                </p>
                            </div>
                        </motion.div>
                        <motion.div initial="hidden" whileInView="visible" viewport={{ once: true, amount: 0.2 }} transition={{ delay:0.2, duration: 0.5 }}
                        variants={{ hidden: { opacity: 0, x: -50, y:-50 }, visible: { opacity: 1, x: 0, y:0 },}}>
                            <div className="md:px-4 xl:px-0 md:mt-8 xl:mr-36 xl:mt-36">
                                <img className="ml-auto mr-auto" src="../assets/mock-design.png" alt="mock design" />
                                <p className="ml-auto mr-auto pt-2 text-center max-w-[350px] text-xl">Mock Design</p>
                                <p className="ml-auto mr-auto pt-2 pb-4 text-center max-w-[450px]">
                                During the mock design stage, designers bring their ideas to life through wireframes and prototypes, offering a tangible glimpse of the final product's layout and 
                                functionality.
                                A mock design brings to life the idea through a series of prototypes. It is a glimps, or snap shot, of what will come and 
                                </p>
                            </div>
                        </motion.div>
                        <motion.div initial="hidden" whileInView="visible" viewport={{ once: true, amount: 0.2 }} transition={{delay:0.4, duration: 0.5 }}
                        variants={{ hidden: { opacity: 0, y: -50 }, visible: { opacity: 1, y: 0 },}}>
                            <div className="md:px-4 xl:px-0 md:mt-8 xl:mt-5">
                                <img className="ml-auto mr-auto" src="../assets/refine.png" alt="refine" />
                                <p className="ml-auto mr-auto pt-2 text-center max-w-[350px] text-xl">Refine</p>
                                <p className="ml-auto mr-auto pt-2 pb-4  text-center max-w-[450px]">
                                    The refinement process involves critical assessment and adjustments, refining design elements and user experiences to achieve a polished and effective end result.
                                </p>
                            </div>
                        </motion.div>
                        <motion.div initial="hidden" whileInView="visible" viewport={{ once: true, amount: 0.2 }} transition={{ delay:0.6, duration: 0.5 }}
                        variants={{ hidden: { opacity: 0, x: 50, y:-50 }, visible: { opacity: 1, x: 0, y:0 },}}>
                            <div className="md:px-4 xl:px-0 md:mt-8 xl:mr-36 xl:ml-36 xl:mt-36">
                                <img className="ml-auto mr-auto" src="../assets/design.png" alt="design" />
                                <p className="ml-auto mr-auto pt-2 text-center max-w-[350px] text-xl">Design</p>
                                <p className="ml-auto mr-auto xl:pt-2 xl:pb-4  text-center max-w-[450px]">
                                    The design phase is where the artistry unfolds, as designers apply their skills to create visually captivating and user-friendly web interfaces, marrying aesthetics with functionality.
                                </p>
                            </div>
                        </motion.div>
                        <motion.div initial="hidden" whileInView="visible" viewport={{ once: true, amount: 0.2 }} transition={{ delay:0.8, duration: 0.5 }}
                        variants={{ hidden: { opacity: 0, x: 50 }, visible: { opacity: 1, x: 0 },}}>
                            <div className="md:px-4 xl:px-0 md:mt-8 xl:mt-80">
                                <img className="ml-auto mr-auto" src="../assets/finishing-touches.png" alt="finishing touches" />
                                <p className="ml-auto mr-auto pt-2 text-center max-w-[350px] text-xl">Finishing Touches</p>
                                <p className="ml-auto mr-auto pt-2 pb-4  text-center max-w-[450px]">
                                    In the finishing touches phase, designers add the final details, animations, and interactions that elevate the website, ensuring it's ready to enchant and engage its audience.
                                </p>
                            </div>
                        </motion.div>
                    </div>                    
                </div>
                <motion.div initial="hidden" whileInView="visible" viewport={{ once: true, amount: 0.2 }} transition={{ delay:1, duration: 0.5 }}
                variants={{ hidden: { opacity: 0, y: 50 }, visible: { opacity: 1, y: 0 },}}>
                    <div className="justify-center xl:-mt-40 mt-10">
                        <img className="xl:w-[900px] ml-auto mr-auto " src="../assets/can-do.png" alt="Things I do." />                        
                            <p className="ml-auto mr-auto pt-2 pb-4  text-center max-w-[700px]">
                                I can design anything from logos to maps, cards, backgrounds, web pages and more.
                            </p>
                    </div>
                </motion.div>               
        </section>
    );
};

export default Projects;